<template>
  <div :class="$style.detail">
    <DetailHeader :headerData="headerData" />
    <div>
      <a-tabs
        :default-active-key="activeKey"
        :tabBarStyle="{ margin: '10px 20px' }"
        @change="changeTab"
      >
        <a-tab-pane key="device-status" tab="实时数据">
          <div class="grid grid-cols-2 p-16 gap-24">
            <div
              class="flex flex-col items-start p-16"
              v-for="(item, index) in realTime"
              :key="index"
            >
              <span>{{ item.name }}</span>
              <span>{{ item.value }}</span>
            </div>
          </div>
        </a-tab-pane>
        <div slot="tabBarExtraContent">
          <div v-if="activeKey === 'device-status'">
            {{ $t('detail.lastUpdateTime') }}：{{ lastUpdateTime }}
          </div>
        </div>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import DetailHeader from '@/views/safety-device/component/detail-header';
import DeviceWarning from '@/views/safety-device/component/device-warning';
import StatusInfo from '@/views/safety-device/component/status-info';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import moment from 'moment';
import { deviceLiveData } from '@/services/device-manage/device-detail';
import { deviceTypeEnum } from '@/enum/device';
import { pageTypeEnum } from '../component/safety-utils';
import {
  deviceStatusEnum,
  onOffEnum,
  underVoltageEnum,
  alarmEnum,
} from './enum';

@Component({
  components: {
    DetailHeader,
    DeviceWarning,
    StatusInfo,
    ScreenSelect,
  },
})
export default class LiftDetail extends Vue {
  @Prop({ type: Object, default: () => {} }) detailData;
  @Prop({ type: String, default: '' }) pageType;

  deviceGroup = deviceTypeEnum[pageTypeEnum.edgeProtection].deviceGroup;
  headerData = {};
  mounted() {
    this.initHeadData();
  }
  created() {
    this.init();
  }
  alarmCount = 0;
  get deviceId() {
    return this.detailData.pkId;
  }

  initHeadData() {
    const data = this.detailData;
    this.headerData = {
      deviceName: data.deviceName,
      deviceCode: data.deviceCode,
      bindTime: data.bindTime,
      projectName: data.projectName,
      remark: data.remark,
    };
  }
  activeKey = 'device-status';
  changeTab(activeKey) {
    this.activeKey = activeKey;
  }
  formateTime(t) {
    return t ? moment(t).format('YYYY-MM-DD HH:mm:ss') : '-';
  }

  async init() {
    this.realTimeData = await deviceLiveData(this.deviceId, this.deviceGroup);
  }

  get lastUpdateTime() {
    return this.formateTime(this.realTimeData.eventTime);
  }
  realTimeData = {};
  get realTime() {
    return [
      {
        name: '设备状态',
        value: deviceStatusEnum[this.realTimeData.deviceStatus] ?? '-',
      },
      {
        name: '电池电量',
        value:
          this.realTimeData.eleNumber > 0 ? this.realTimeData.eleNumber : '-',
      },
      {
        name: '布防状态',
        value: onOffEnum[this.realTimeData.defenseStatus] ?? '-',
      },
      {
        name: '欠压状态',
        value: underVoltageEnum[this.realTimeData.underTension] ?? '-',
      },
      {
        name: '线锁1',
        value: alarmEnum[this.realTimeData.wireLock1] ?? '-',
      },
      {
        name: '线锁2',
        value: alarmEnum[this.realTimeData.wireLock2] ?? '-',
      },
      {
        name: '磁锁1',
        value: alarmEnum[this.realTimeData.magneticLock1] ?? '-',
      },
      {
        name: '磁锁2',
        value: alarmEnum[this.realTimeData.magneticLock1] ?? '-',
      },
    ];
  }
}
</script>

<style lang="less" module>
@import '../component/safety.less';
</style>
